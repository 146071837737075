import { TimeMilliseconds } from "constants/time";

interface Options {
  timeout: number;
  isInstantCall: boolean;
}

class Interval {
  public static intervals = new Map<number, Interval>();
  private static nexId = 0;

  private timeoutId: NodeJS.Timeout | null = null;

  private callTimestamp: number | null = null;
  private pauseTimestamp: number | null = null;

  public id = ++Interval.nexId;

  private readonly options: Options = {
    timeout: TimeMilliseconds.second * 15,
    isInstantCall: false,
  };

  constructor(
    private callback: () => Promise<unknown> | unknown,
    options: Partial<Options> = {},
  ) {
    this.options = {
      ...this.options,
      ...options,
    };

    Interval.intervals.set(this.id, this);

    this.start();
  }

  public get status() {
    if (this.timeoutId && this.callTimestamp) {
      return "running";
    }

    if (this.pauseTimestamp) {
      return "paused";
    }

    return "stopped";
  }

  private create(timeout: number) {
    const callback = async () => {
      await this.callback();

      if (this.timeoutId) {
        this.timeoutId = setTimeout(callback, this.options.timeout);
        this.callTimestamp = Date.now();
      } else {
        this.callTimestamp = null;
      }
    };

    this.timeoutId = setTimeout(callback, timeout);
    this.callTimestamp = Date.now();
    this.pauseTimestamp = null;
  }

  public async start(timeout = this.options.timeout) {
    this.stop();

    if (this.options.isInstantCall) {
      await this.callback();
    }

    this.create(timeout);
  }

  public stop() {
    if (!this.timeoutId) {
      return;
    }

    clearTimeout(this.timeoutId);
    this.timeoutId = null;
  }

  public pause() {
    if (!this.timeoutId) {
      return;
    }

    this.pauseTimestamp = Date.now();

    this.stop();
  }

  public resume() {
    if (!this.pauseTimestamp) {
      return;
    }

    return this.start(
      this.options.timeout - (this.pauseTimestamp - this.callTimestamp),
    );
  }

  public dispose() {
    this.stop();
    Interval.intervals.delete(this.id);
  }

  public async flush() {
    this.stop();
    await this.callback();
    this.create(this.options.timeout);
  }

  public static pauseAll() {
    this.intervals.forEach(interval => interval.pause());
  }

  public static resumePausedAll() {
    this.intervals.forEach(interval => {
      if (interval.status === "paused") {
        interval.flush();
      }
    });
  }

  public static disposeAll() {
    this.intervals.forEach(interval => interval.dispose());
  }
}

export { Interval };
